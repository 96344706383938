
.hak-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00114d;
    color: white;
    max-width: 667px;
    margin: auto;
    position: relative;
    font-family: 'VT323',sans-serif;
}
.hamburger-menu{
    padding: 1.5% 6%;
    text-align: right;
}
.home-block{
    padding: 2% 6%;
}
.home-block  h1{
    font-size: 14.5vw;
    color: #35fefe;
    word-spacing: -10px;
}
.home-block > .block-content{
    margin: 5vw 0;
    font-size: 4.8vw;
    word-spacing: 5px;
    letter-spacing: 0px;
}
.home-block > .co-founders{
    font-size: 8.2vw;
    word-spacing: 5px;
    letter-spacing: 7px;
    line-height: 8vw ;
}
.pink{
    font-size: 150%;
    color: #e615cd;
}
.drink-menu div:first-child{
    margin-bottom: 10%;
}
.games-list tr:last-of-type{
    height: 8vw;
    display: flex;
    align-items: flex-end;
    vertical-align: bottom;
    color: #e615cd;
}
.divider{
    border-top: 8px solid #e615cd;
    border-bottom: 8px solid #35fefe;
    margin: 20px 0px;
}
.login-bar{
    border: 1px solid white;
    margin: 0px;
}
.login-bg{
    background-color: black;
}
.logout-bg{
    background-color: #00114d;
}
.login-search{
    background-color: #464646;
}
.logout-search{
    background-color: #46527d ;
}
.menu-container{
    background-color: #00114d;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    padding: 2vw;
    height: 100vh;
    overflow-y: scroll;
    z-index: 1;
}

.list{
    list-style-type: none;
    padding: 0px;
}
.menu-items{
    margin-top: 20px;
}
.menu-items > li{
    font-size: 10vw;
    border-bottom: 1px solid rgb(253, 89, 254);
    text-align: center;
    height: 14vw;
    color: #35fefe;
}

.search-container{
    width:100%;
    justify-content: space-between;
    gap: 2vw;
    border-radius: 100px;
    padding: 2vw 5vw;
    margin-top: 6%;
    margin-bottom: 8%;
}
.search-field{
    width:100%;
    border:none;
    background-color: transparent;
    font-size: 4vw;
    color: #ffffff;
}
.show-menu{
    animation: dropdown 0.5s linear 0s 1 normal forwards;
}
.hide-menu{
    animation: moveup 1s linear 0s 1 normal forwards;
}
@keyframes dropdown{
    0%{
        top: -100%;
        height: 150vh;
    }
    100%{
        top: 0px;
        height: 100vh;
    }
}
@keyframes moveup{
    from{
        top: 0px;
        height: 100vh;}
    to{
        top: -200%;
        height: 150vh;
    }
}
.search-field::placeholder{
    color:#9ea4bb;
    letter-spacing: 0px;
}
.search-field:focus-visible{
    outline:none;
}
input[type="search"]::-webkit-search-cancel-button{
    -webkit-appearance:none;
}
.desktop-container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    background-size: 18rem;
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.desktop-icons-container {
    display: flex;
    align-content: flex-start;
    padding: 10vw 10vw 0vw 10vw;
    flex-wrap: wrap;
    gap: 20%;
    min-height: 92vw;
}

.rcw-message > .desktop-icons-container {
    min-height: auto;
}
.desktop-icon {
    width: 20vw;
    height: 40vw;
    cursor: pointer;
    margin-bottom:20px
}

.pointer{
    cursor: pointer;
}
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background-color: rgba(35, 31, 32, 255);
    height: 2.5rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;

}

.footer>div:first-child {
    cursor: pointer;
}
::-webkit-scrollbar {
    width: 15px;
}
::-webkit-scrollbar-track{
    background: #000000;
    border: 1px solid #ffffff;
}
::-webkit-scrollbar-thumb {
    background: #ffffff; 
}
.logout-bg::-webkit-scrollbar-track {
    background: #344eaa; 
}

.logout-bg::-webkit-scrollbar-thumb {
    background: rgb(253, 89, 254); 
}

.login-bg::-webkit-scrollbar-track{
    background: #000000;
    border: 1px solid #ffffff;
}
.login-bg::-webkit-scrollbar-thumb {
    background: #ffffff; 
}
footer{
    border-top: 2px solid rgb(253, 89, 254);
    margin: 5%;
    padding: 20px;
    text-align: center;
    color: #35fefe;
    font-size: 4.8vw;
}
.arrow-icon{
    cursor: pointer;
}
@media screen and (max-width: 800px) {
    .desktop-container {
        background-size: 10rem;
    }
}
@media screen and (min-width: 667px) {
    .home-block  h1{
        font-size: 6rem;
    }
    .home-block > .block-content{
        font-size: 2rem;
        margin: 20px 0;
    }
    footer{
        font-size: 2rem;
    }
    .home-block > .co-founders{
        font-size: 3.5rem;
        line-height: 55px ;
    }
    .search-field{
        font-size: 1.6rem; 
    }
    .search-container{
        padding: 15px 30px;
    }
    .desktop-container{
        padding-top: 40px;
    }
    .games-list tr:last-of-type{
        height: 50px;
    }
    .menu-items > li{
        font-size: 4.2rem;
        height: 92px;
    }
    /* ::-webkit-scrollbar {
        width: 15px;
    } */
    .desktop-icons-container {
        padding: 70px 160px 0px 70px;
        min-height: auto;
    }
    
    .desktop-icon {
        width: 105px;
        height: 200px;
    }
    .chat-toggle{
        font-size: 42px;
        padding: 8px 100px;
    }
}

.hak-modal {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 5%;
    background-color: white;
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    z-index: 2;
}

.hak-modal-pdf {
    position: absolute;
    top: 3%;
    left: 3%;
    right: 3%;
    bottom: 5%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
    z-index: 2;
}

.hak-modal-header { 
    display: flex;
    align-items: center;
    background-color: rgba(179, 179, 179, 255);
    height: 35px;
    color: black;
    cursor: move;
    font-weight: 500;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

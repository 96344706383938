
.rcw-conversation-container {
    box-shadow: none;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
}

.rcw-sender {
    align-items: center;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    max-height: none;
    min-height: 150px;
    gap:20px;
    z-index: -1;
}

.rcw-picker-btn {
    display: none;
}

.rcw-new-message {
    width: 100%;
    border-radius: 0;
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #ffffff;
    height: 20vw;
    text-align: left;
    padding: 20px;
    font-size: 20px;
}

.rcw-send::before {
    content: "ENTER";
}

.rcw-send {
    border-radius: 0;
    background-color: #000000;
    padding: 8px 45px;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-weight: 600;
    font-size: x-large;
}

.rcw-send .rcw-send-icon {
    display: none;
}

.rcw-conversation-container>.rcw-header {
    padding: 5px 0 5px !important;
    border-radius: 0px;
    border-bottom: 1px solid;
    background-color: #fff0 !important;
}

.rcw-message-text{
    background-color: #fff0 !important;
    max-width: 100% !important;
    padding: 10px 15px !important;
}
.rcw-client>.rcw-message-text {
    color: #ff00ff;
}
.rcw-open-launcher {
    animation-duration: 0ms;
}
.rcw-message-client {
    display: inline !important;
}
.rcw-timestamp {
    display: none;
}
.rcw-client>.rcw-message-text>p:before {
    content: "YOU: ";
    font-weight: 600;
}
.rcw-response>.rcw-message-text>p:before {
    content: "VINCENT: ";
    font-weight: 600;
}
.rcw-launcher {
    display: inline;
    align-items: center;
    background-color: transparent;
    width: 16rem;
    height: 2.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    box-shadow: none;
}


.rcw-launcher>img {
    background-color: none;
    width: 100%;
}

.rcw-response>.rcw-message-text, .rcw-input {
    max-height: 100px;
    color: white;
}

.rcw-close {
    width: 20px !important;
}

.rcw-conversation-container .rcw-close-button {
    display: block !important;
    background-color: transparent !important;
    border: 0;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px;
    box-shadow: none;
}

.rcw-widget-container {
    z-index: 1;
    margin: 0px;
    text-align: center;
    width: 100% !important;
    height: 100vh !important;
    bottom: 0;
    display: inherit;
    flex-direction: column;
    position: initial;
    right: auto;
}

.rcw-title {
    margin-left: 20px !important;
    text-align: center;
    padding: 15px 0px !important;
}

.rcw-close-widget-container{
    z-index: 0 !important;
    height: max-content !important;
}

.rcw-messages-container {
    background-color: #fff0;
}
.rcw-message{
    margin: 0px;
    max-width: 85%;
}
.chatToggle{
    padding-top: 0px !important;
}
.rcw-messages-container::-webkit-scrollbar-track{
    background: #000000;
    border: 1px solid #ffffff;
}
.rcw-messages-container::-webkit-scrollbar-thumb {
    background: #ffffff; 
}
.chat-toggle{
    font-weight: 600;
    border-radius: 0;
    background-color: #000000;
    padding: 1vw 14vw;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 8vw;
}
.button-area{
    text-align: right;
    position: relative;
    margin: 5% 5% 10% 5%;
}
.custom-badge{
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0px 10px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: x-large;
}
@media screen and (max-width:800px) {
    .rcw-widget-container {
        width: 100% !important;
    }
    .rcw-conversation-container {
        width: 100% !important;
        max-width: none;
        min-width: auto;
    }
}

.rcw-close-launcher, .rcw-hide-sm {
    display: none;
}